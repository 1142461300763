import React, { useCallback, useMemo, useState, useEffect } from 'react';
import idx from 'idx';
import gql from 'graphql-tag';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import addYears from 'date-fns/addYears';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import differenceInDays from 'date-fns/differenceInDays';
import { Theme, FilledInput } from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { makeStyles, createStyles } from '@material-ui/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import formatDate from '@/helpers/formatDate';
import CalendarFormField from '@/form-fields/Calendar';
import { SelfExcludeV3, SelfExcludeV3Variables } from '@/types/generated/SelfExcludeV3';
import { ClosureType } from '@/constants';
import { GET_PLAYER_CLOSURES } from '@/containers/PlayerClosureHistory';
import { PlayerClosures, PlayerClosuresVariables } from '@/types/generated/PlayerClosures';
import PlayerClosureHistoryTable from '@/containers/PlayerClosureHistory/PlayerClosureHistoryTable';
import { GetSelfExclusions, GetSelfExclusionsVariables } from '@/types/generated/GetSelfExclusions';
import TextFieldFormField from '@/form-fields/TextField';

interface PropsType {
  playerId: string;
  data: any;
  refetch?: any;
}

const SELF_EXCLUDE = gql`
  mutation SelfExcludeV3($playerId: ID!, $exclusionEndsAt: OffsetDateTime, $comment: String!) {
    selfExcludeV3(playerId: $playerId, exclusionEndsAt: $exclusionEndsAt, comment: $comment) {
      selfExclusionDetails {
        id
        configuredAt
        exclusionEndsAt
        willBeCancelledAt
        pending {
          activeFrom
          configuredAt
          exclusionEndsAt
        }
      }
    }
  }
`;

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      commentTextfieldInput: {
        marginTop: theme.spacing(2.5),
        paddingTop: 10,
      },
      datePaper: {
        width: 'auto',
        overflow: 'hidden',
        minWidth: 320,
        marginTop: theme.spacing(2.5),
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: theme.spacing(2.5),
      },
      select: { width: '100%' },
    }),
  { name: 'TimeoutForm' }
);

const TimeoutForm = (props: PropsType) => {
  const { playerId, data, refetch } = props;
  const classes = useStyles(props);

  const [selfExcludeV3] = useMutation<SelfExcludeV3, SelfExcludeV3Variables>(SELF_EXCLUDE);
  const { enqueueSnackbar } = useSnackbar();

  const configuredAtStr = idx(data, (_) => _.configuredAt);
  const exclusionEndsAtStr = idx(data, (_) => _.exclusionEndsAt);
  const willBeCancelledAtStr = idx(data, (_) => _.willBeCancelledAt);

  const configuredAt = configuredAtStr ? new Date(configuredAtStr) : null;
  const exclusionEndsAt = exclusionEndsAtStr ? new Date(exclusionEndsAtStr) : null;
  const [exclusionValue, setExclusionValue] = useState('1d');

  const willBeCancelledAt = willBeCancelledAtStr ? new Date(willBeCancelledAtStr) : null;

  const onSubmit = useCallback(
    async (values) => {
      try {
        const today = new Date();
        let value = null;

        switch (values.exclusionValue) {
          case '1d':
            value = today.setDate(today.getDate() + 1);
            break;
          case '7d':
            value = today.setDate(today.getDate() + 7);
            break;
          case '1':
            value = today.setMonth(today.getMonth() + 1);
            break;
          case '3':
            value = today.setMonth(today.getMonth() + 3);
            break;
          case '6':
            value = today.setMonth(today.getMonth() + 6);
            break;
          case '12':
            value = today.setMonth(today.getMonth() + 12);
            break;
          case 'indefinite':
            value = null;
            break;
          default:
            value = values.exclusionEndsAt;
        }

        await selfExcludeV3({
          variables: {
            exclusionEndsAt: value ? new Date(value) : null,
            comment: values?.comment,
            playerId,
          },
          update(cache, response) {
            const node = response?.data?.selfExclusionDetails;

            const data = cache.readQuery<GetSelfExclusions>({
              query: GET_PLAYER_CLOSURES,
              variables: {
                playerId,
              },
            });

            cache.writeQuery<GetSelfExclusions, GetSelfExclusionsVariables>({
              query: GET_PLAYER_CLOSURES,
              data: Object.assign({}, data, {
                player: {
                  id: playerId,
                  __typename: 'Player',
                  selfExclusionDetails: {
                    __typename: 'SelfExclusionData',
                    ...(idx(data, (_) => _.player.selfExclusionDetails) || []),
                  },
                },
              }),
              variables: {
                playerId,
              },
            });
          },
        });
        enqueueSnackbar('Self Exclusion has been created', { variant: 'success' });
        await refetch();
      } catch (e) {
        enqueueSnackbar(`Failed to create Self Exclusion: ${e}`, { variant: 'error' });
      }
    },
    [playerId, enqueueSnackbar, selfExcludeV3, refetch, setExclusionValue]
  );

  const initialValues = useMemo(
    () => ({
      exclusionValue: exclusionValue,
      exclusionEndsAt: exclusionEndsAt || addYears(Date.now(), 1),
    }),
    [exclusionEndsAt, exclusionValue]
  );

  const onChange = (item: any) => {
    setExclusionValue(item?.target?.value);
  };

  return (
    <>
      <Form onSubmit={onSubmit} initialValues={initialValues}>
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            {!!exclusionEndsAt && (
              <Typography paragraph>
                The user has been already configured at {formatDate(configuredAt)} to be excluded
                until {formatDate(willBeCancelledAt || exclusionEndsAt)}
              </Typography>
            )}
            <Grid container spacing={2} direction="row">
              <Grid item xs={12}>
                <InputLabel shrink>Select Date</InputLabel>
                <FormControl fullWidth disabled={!!exclusionEndsAt}>
                  <Select
                    className={classes.select}
                    disableUnderline
                    input={<FilledInput />}
                    onChange={onChange}
                    name="exclusionValue"
                    value={exclusionValue}
                  >
                    <MenuItem value={'1d'}>24 HOURS</MenuItem>
                    <MenuItem value={'7d'}>7 DAYS</MenuItem>
                    <MenuItem value={'1'}>1 MONTH</MenuItem>
                    <MenuItem value={'3'}>3 MONTHS</MenuItem>
                    <MenuItem value={'6'}>6 MONTHS</MenuItem>
                    <MenuItem value={'12'}>12 MONTHS</MenuItem>
                    <MenuItem value={'indefinite'}>INDEFINITE</MenuItem>
                    <MenuItem value={'custom'}>CUSTOM</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth disabled={!!exclusionEndsAt}>
                  {exclusionValue === 'custom' && (
                    <Paper className={classes.datePaper}>
                      <Field
                        disablePast
                        name="exclusionEndsAt"
                        component={CalendarFormField}
                        inputVariant="filled"
                        pickerFormat="LLL do, yyyy HH:mm"
                        allowKeyboardControl={false}
                      />
                    </Paper>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <FormControl fullWidth margin="normal">
              <InputLabel shrink>A Note</InputLabel>
              <Field
                multiline
                fullWidth
                name="comment"
                rows="4"
                variant="filled"
                component={TextFieldFormField}
                placeholder="Write a player note"
              />
            </FormControl>
            <FormControl fullWidth margin="normal">
              <Button
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
                disabled={!!exclusionEndsAt || submitting || !!willBeCancelledAt}
              >
                SUBMIT
              </Button>
            </FormControl>
          </form>
        )}
      </Form>
    </>
  );
};

export default TimeoutForm;
