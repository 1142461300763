import toNumber from 'lodash-es/toNumber';
import isFinite from 'lodash-es/isFinite';
import { PLACEHOLDER } from '@/constants';

export default function formatMoney(amount?: number | string | null, currency?: string | null) {
  const num = toNumber(amount);

  if (!isFinite(num)) {
    return PLACEHOLDER;
  }

  return new Intl.NumberFormat('fr-FR', {
    style: currency ? 'currency' : 'decimal',
    currency: currency || undefined,
    currencyDisplay: 'code',
    maximumFractionDigits: 2
  }).format(num)
}
