import React, { memo } from 'react';
import idx from 'idx';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { PeriodValue, PeriodValues } from './types';
import formatDate from '@/helpers/formatDate';

interface PropsType {
  current?: PeriodValues | null;
  available?: PeriodValues | null;
}

function formatValue(value?: PeriodValue) {
  if (!value) {
    return '-';
  }

  if (value <= 0) {
    return 0;
  }

  return String(value);
}

const CurrentLimitsAvailableValues = (props: PropsType) => {
  const { current, available, currentPeriodEndTime } = props;

  return (
    <Paper>
      <Box pt={3} pr={5} pb={3} pl={5}>
        <Typography paragraph variant="h4">
          CURRENT LIMITS
        </Typography>
        <Grid container spacing={8}>
          <Grid item>
            <Typography variant="h5">DAILY</Typography>
            <Typography>{formatValue(idx(current, (_) => _.day))}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5">WEEKLY</Typography>
            <Typography>{formatValue(idx(current, (_) => _.week))}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5">MONTHLY</Typography>
            <Typography>{formatValue(idx(current, (_) => _.month))}</Typography>
          </Grid>
        </Grid>
        <Box mt={3} />
        <Typography paragraph variant="h4">
          AVAILABLE AMOUNT
        </Typography>
        <Grid container spacing={6}>
          <Grid item>
            <Typography variant="h5">DAILY</Typography>
            <Typography>{formatValue(idx(available, (_) => _.day))}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5">WEEKLY</Typography>
            <Typography>{formatValue(idx(available, (_) => _.week))}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5">MONTHLY</Typography>
            <Typography>{formatValue(idx(available, (_) => _.month))}</Typography>
          </Grid>
        </Grid>
        <Box mt={3} />
        <Typography paragraph variant="h4">
          RESET TIME
        </Typography>
        <Grid container spacing={6}>
          <Grid item>
            <Typography variant="h5">DAILY</Typography>
            <Typography>{formatDate(currentPeriodEndTime?.day)}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5">WEEKLY</Typography>
            <Typography>{formatDate(currentPeriodEndTime?.week)}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5">MONTHLY</Typography>
            <Typography>{formatDate(currentPeriodEndTime?.month)}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default memo(CurrentLimitsAvailableValues);
