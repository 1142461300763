import React, { useCallback, useState } from 'react';
import Button from '@material-ui/core/Button';
import toFinite from 'lodash-es/toFinite';
import FormControl from '@material-ui/core/FormControl';
import { useSnackbar } from 'notistack';
import { Form, Field } from 'react-final-form';

import TextFieldFormField from '@/form-fields/TextField';

export interface PropsType {
  label: string;
  onSubmit: Function;
  onRemove: Function;
  placeholder?: string;
}

const PeriodAmountLimitForm = (props: PropsType) => {
  const { label, placeholder, onRemove: onRemoveProp, onSubmit: onSubmitProp } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [removing, setRemoving] = useState(false);

  const onSubmit = useCallback(
    async (values, form) => {
      try {
        await onSubmitProp({ amount: toFinite(values.amount) });
        enqueueSnackbar('Limit has been created', { variant: 'success' });
        setTimeout(() => form.reset(), 10);
      } catch (e) {
        enqueueSnackbar(`Failed to create limit: ${e}`, { variant: 'error' });
      }
    },
    [onSubmitProp, enqueueSnackbar]
  );

  const onRemove = useCallback(
    async form => {
      try {
        setRemoving(true);
        await onRemoveProp();
        enqueueSnackbar('Limit has been removed', { variant: 'success' });
        setTimeout(() => form.reset(), 10);
      } catch (e) {
        enqueueSnackbar(`Failed to remove the limit: ${e}`, { variant: 'error' });
      } finally {
        setRemoving(false);
      }
    },
    [onRemoveProp, enqueueSnackbar, setRemoving]
  );

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, submitting, form }) => (
        <form onSubmit={handleSubmit}>
          <Field
            fullWidth
            name="amount"
            label={label}
            margin="normal"
            variant="filled"
            component={TextFieldFormField}
            InputProps={{ disableUnderline: true }}
            placeholder={placeholder}
            InputLabelProps={{ shrink: true }}
          />
          <FormControl margin="normal" fullWidth>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={submitting || removing}
            >
              SUBMIT
            </Button>
          </FormControl>
          <FormControl margin="normal" fullWidth>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => onRemove(form)}
              disabled={submitting || removing}
            >
              REMOVE
            </Button>
          </FormControl>
        </form>
      )}
    </Form>
  );
};

export default PeriodAmountLimitForm;
