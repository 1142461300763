import React, { memo } from 'react';
import idx from 'idx';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import formatDate from '@/helpers/formatDate';

import CancelPendingSelfExclusionButton from './CancelPendingSelfExclusionButton';

interface PropsType {
  selfExclusions: any;
  playerId: any;
}

const PendingSelfExclusionValues = (props: PropsType) => {
  const { playerId, selfExclusions } = props;

  if (!selfExclusions?.pending) {
    return null;
  }

  return (
    <Paper>
      <Box pt={3} pr={5} pb={3} pl={5}>
        <Typography paragraph variant="h4">
          PENDING SELF EXCLUSION
        </Typography>
        <Grid container spacing={8}>
          <Grid item>
            <Typography variant="h5">Active from</Typography>
            <Typography>{formatDate(idx(selfExclusions, (_) => _.pending.activeFrom))}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5">Configured At</Typography>
            <Typography>
              {formatDate(idx(selfExclusions, (_) => _.pending.configuredAt))}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5">Ends at</Typography>
            <Typography>
              {formatDate(idx(selfExclusions, (_) => _.pending.exclusionEndsAt))}
            </Typography>
          </Grid>
          <Grid item sm={12}>
            <Typography variant="h5">Cancel Comment</Typography>
            <CancelPendingSelfExclusionButton playerId={playerId} exclusionId={selfExclusions.id} />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default memo(PendingSelfExclusionValues);
