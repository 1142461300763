import React, { useEffect, useContext } from 'react';
import idx from 'idx';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import AppContext from '@/contexts/AppContext';

import PendingLimits from './PendingLimits';
import CurrentLimitsAvailableValues from './CurrentLimitsAvailableValues';
import PeriodAmountLimitForm, {
  PropsType as PeriodAmountLimitFormPropsType,
} from './PeriodAmountLimitForm';
import { PeriodValues } from './types';

interface ValuesType {
  current?: PeriodValues;
  pending?: PeriodValues;
  available?: PeriodValues;
}

interface PropsType {
  title: string;
  loading: boolean;
  values?: ValuesType;
  onSubmitDay: Function;
  onRemoveDay: Function;
  onSubmitWeek: Function;
  onRemoveWeek: Function;
  onSubmitMonth: Function;
  onRemoveMonth: Function;
  dailyPlaceholder: PeriodAmountLimitFormPropsType['placeholder'];
  weeklyPlaceholder: PeriodAmountLimitFormPropsType['placeholder'];
  monthlyPlaceholder: PeriodAmountLimitFormPropsType['placeholder'];
  onCancelPendingRequests: Function;
}

const PlayerLimit = (props: PropsType) => {
  const {
    title,
    values,
    loading,
    onSubmitDay,
    onRemoveDay,
    onSubmitWeek,
    onRemoveWeek,
    onSubmitMonth,
    onRemoveMonth,
    dailyPlaceholder,
    weeklyPlaceholder,
    monthlyPlaceholder,
    onCancelPendingRequests,
  } = props;

  const { setLoading } = useContext(AppContext);

  useEffect(() => {
    setLoading(!!loading);
  }, [loading, setLoading]);

  return (
    <div>
      <Typography paragraph variant="h4">
        {title}
      </Typography>
      <Box mt={4} />
      <Grid container spacing={4}>
        <Grid item sm={12} md={4}>
          <PeriodAmountLimitForm
            label="Daily"
            onSubmit={onSubmitDay}
            onRemove={onRemoveDay}
            placeholder={dailyPlaceholder}
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <PeriodAmountLimitForm
            label="Weekly"
            onSubmit={onSubmitWeek}
            onRemove={onRemoveWeek}
            placeholder={weeklyPlaceholder}
          />
        </Grid>
        <Grid item sm={12} md={4}>
          <PeriodAmountLimitForm
            label="Monthly"
            onSubmit={onSubmitMonth}
            onRemove={onRemoveMonth}
            placeholder={monthlyPlaceholder}
          />
        </Grid>
      </Grid>
      <Box mt={4} />
      <CurrentLimitsAvailableValues
        current={idx(values, (_) => _.current)}
        available={idx(values, (_) => _.available)}
        currentPeriodEndTime={idx(values, (_) => _.currentPeriodEndTime)}
      />
      <Box mt={4} />
      <PendingLimits
        {...(idx(values, (_) => _.pending) || {})}
        onCancelPendingRequests={onCancelPendingRequests}
      />
    </div>
  );
};

export default PlayerLimit;
