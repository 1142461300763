import { parsePhoneNumberFromString } from 'libphonenumber-js';
import flag from 'country-code-emoji';
import { PLACEHOLDER } from '@/constants';

export default function formatValue(v?: string | null): string {
  const phoneNumber = v ? parsePhoneNumberFromString(v) : null;

  return phoneNumber
    ? `${
        phoneNumber?.country ? flag(phoneNumber?.country) : ''
      }${phoneNumber?.formatInternational()}`
    : PLACEHOLDER;
}
