import React, { useCallback } from 'react';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/react-hooks';

import Button from '@material-ui/core/Button';
import { ClosureStatus } from '@/types/generated/globalTypes';
import { CancelSelfExclusion } from '@/types/generated/CancelSelfExclusion';
import PlayerClosureHistoryTable from './PlayerClosureHistoryTable';
import { PlayerClosureHistoryTableRow } from '@/types/generated/PlayerClosureHistoryTableRow';

interface PropsType {
  playerId: string;
  exclusionId: string;
  status: ClosureStatus;
}

const CANCEL_SELF_EXCLUSION = gql`
  mutation CancelSelfExclusion($playerId: ID!) {
    cancelSelfExclusionV2(playerId: $playerId) {
      selfExclusionDetails {
        id
        configuredAt
        exclusionEndsAt
        willBeCancelledAt
        pending {
          activeFrom
          configuredAt
          exclusionEndsAt
        }
      }
    }
  }
`;

const CancelSelfExclusionButton = (props: PropsType) => {
  const { playerId, exclusionId, status } = props;

  const [cancelSelfExclusionV2] = useMutation<CancelSelfExclusion>(CANCEL_SELF_EXCLUSION);
  const { enqueueSnackbar } = useSnackbar();

  const onClick = useCallback(
    async (_values) => {
      try {
        await cancelSelfExclusionV2({
          variables: {
            playerId,
            exclusionId,
          },
          update(cache) {
            const row = cache.readFragment<PlayerClosureHistoryTableRow>({
              id: exclusionId,
              fragment: PlayerClosureHistoryTable.fragments.row,
            });
            if (row) {
              cache.writeFragment({
                id: exclusionId,
                fragment: PlayerClosureHistoryTable.fragments.row,
                data: {
                  ...row,
                  status: 'Cancelled',
                  __typename: 'ClosureSearchItem',
                },
              });
            }
          },
        });
        enqueueSnackbar('SelfExclusion has been cancelled', { variant: 'success' });
      } catch (e) {
        enqueueSnackbar(`Failed to cancel timeout: ${e}`, { variant: 'error' });
      }
    },
    [playerId, enqueueSnackbar, cancelSelfExclusion]
  );

  return (
    <Button
      type="button"
      color="primary"
      variant="contained"
      disabled={status !== 'Active'}
      onClick={onClick}
    >
      CANCEL
    </Button>
  );
};

export default CancelSelfExclusionButton;
