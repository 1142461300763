/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Statuses of a player accounts
 */
export enum AccountStatusEnum {
  Closed = "Closed",
  Open = "Open",
}

/**
 * The aml risk level of a player
 */
export enum AmlRiskLevel {
  High = "High",
  Low = "Low",
  Medium = "Medium",
  NotSet = "NotSet",
}

/**
 * The brand of the site.
 */
export enum BrandEnum {
  AMOK = "AMOK",
  MRGLD = "MRGLD",
  MTGLD = "MTGLD",
  RAPTR = "RAPTR",
  RFLCN = "RFLCN",
}

/**
 * Cause of closing account.
 */
export enum CloseAccountCause {
  AbusiveBehaviour = "AbusiveBehaviour",
  BlockCustomerRequest = "BlockCustomerRequest",
  CloseCustomerRequest = "CloseCustomerRequest",
  ConfirmedFraud = "ConfirmedFraud",
  ConfirmedPromotionAbuse = "ConfirmedPromotionAbuse",
  MigrationCause = "MigrationCause",
  MultipleAccounts = "MultipleAccounts",
  Other = "Other",
  PEPSanctionsListMatch = "PEPSanctionsListMatch",
  ResponsibleGaming = "ResponsibleGaming",
  SanctionsListMatch = "SanctionsListMatch",
  SuspectedFraud = "SuspectedFraud",
  SuspectedPromotionAbuse = "SuspectedPromotionAbuse",
}

/**
 * The status of closure.
 */
export enum ClosureStatus {
  Active = "Active",
  Cancelled = "Cancelled",
  Inactive = "Inactive",
}

/**
 * Type of closure.
 */
export enum ClosureType {
  SelfExclusion = "SelfExclusion",
  Timeout = "Timeout",
}

/**
 * Document verification type
 */
export enum DocumentVerificationType {
  Address = "Address",
  Age = "Age",
  Email = "Email",
  Identity = "Identity",
  PhoneNumber = "PhoneNumber",
  SourceOfWealth = "SourceOfWealth",
}

export enum FundingOption {
  Dividends = "Dividends",
  Inheritance = "Inheritance",
  Other = "Other",
  Salary = "Salary",
  SaleOfCompany = "SaleOfCompany",
  SaleOfProperty = "SaleOfProperty",
}

/**
 * Name of game provider.
 */
export enum GameProvider {
  Authentic = "Authentic",
  Betsoft = "Betsoft",
  Booming = "Booming",
  Elk = "Elk",
  Evolution = "Evolution",
  Ezugi = "Ezugi",
  Gaming1x2 = "Gaming1x2",
  ISoftBet = "ISoftBet",
  Microgaming = "Microgaming",
  Netent = "Netent",
  Nolimit = "Nolimit",
  Pariplay = "Pariplay",
  PlayNGo = "PlayNGo",
  Pragmatic = "Pragmatic",
  Quickspin = "Quickspin",
  Relax = "Relax",
  Scientific = "Scientific",
  Swintt = "Swintt",
  Thunderkick = "Thunderkick",
  Wazdan = "Wazdan",
  Yggdrasil = "Yggdrasil",
}

/**
 * The order to list game rounds.
 */
export enum GameRoundSearchOrder {
  brand = "brand",
  closingBalance = "closingBalance",
  completedAt = "completedAt",
  currency = "currency",
  gameId = "gameId",
  gameProvider = "gameProvider",
  gameRoundId = "gameRoundId",
  lastProcessedAt = "lastProcessedAt",
  playerId = "playerId",
  playerSessionId = "playerSessionId",
}

/**
 * The status of a game round.
 */
export enum GameRoundStatus {
  Loss = "Loss",
  Pending = "Pending",
  Win = "Win",
}

/**
 * Type of limit.
 */
export enum LimitType {
  Deposit = "Deposit",
  Loss = "Loss",
  Session = "Session",
  Wager = "Wager",
}

/**
 * The limit period type.
 */
export enum LimitTypeEnum {
  Day = "Day",
  Month = "Month",
  Week = "Week",
}

/**
 * Status of a payment.
 */
export enum PaymentStatus {
  Cancelled = "Cancelled",
  Completed = "Completed",
  Initialized = "Initialized",
}

/**
 * Type of a payment
 */
export enum PaymentType {
  Deposit = "Deposit",
  Withdrawal = "Withdrawal",
}

/**
 * The compliance status of a player
 */
export enum PlayerComplianceStatus {
  FormSubmitted = "FormSubmitted",
  Pending = "Pending",
  PendingDocuments = "PendingDocuments",
  Rejected = "Rejected",
  UploadedDocuments = "UploadedDocuments",
  Verified = "Verified",
}

/**
 * The order to list player search results.
 */
export enum PlayerSearchOrder {
  balance = "balance",
  brand = "brand",
  city = "city",
  countryCode = "countryCode",
  dateOfBirth = "dateOfBirth",
  firstName = "firstName",
  lastLoginAt = "lastLoginAt",
  lastName = "lastName",
  phoneNumber = "phoneNumber",
  playerId = "playerId",
  registeredAt = "registeredAt",
  zipCode = "zipCode",
}

/**
 * Priorities.
 */
export enum PriorityEnum {
  HIGH = "HIGH",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
}

/**
 * Registration status: Registered or NotRegistered
 */
export enum RegistrationStatusFilter {
  PlayerNotRegistered = "PlayerNotRegistered",
  PlayerRegistered = "PlayerRegistered",
}

/**
 * Reason why timeout was set.
 */
export enum TimeoutReasonEnum {
  GamblingProblem = "GamblingProblem",
  LosingTooMuch = "LosingTooMuch",
  PlayingTooMuch = "PlayingTooMuch",
}

/**
 * The order to list transaction search results.
 */
export enum TransactionSearchOrder {
  amount = "amount",
  brand = "brand",
  gameId = "gameId",
  gameProvider = "gameProvider",
  gameRoundId = "gameRoundId",
  playerId = "playerId",
  processedAt = "processedAt",
}

/**
 * Type of a transaction.
 */
export enum TransactionType {
  AdjustmentDeposit = "AdjustmentDeposit",
  AdjustmentWithdrawal = "AdjustmentWithdrawal",
  CancelDeposit = "CancelDeposit",
  CancelWithdrawal = "CancelWithdrawal",
  Cashback = "Cashback",
  CasinoBet = "CasinoBet",
  CasinoDeposit = "CasinoDeposit",
  CasinoWin = "CasinoWin",
  Deposit = "Deposit",
  InitDeposit = "InitDeposit",
  InitRollbackDeposit = "InitRollbackDeposit",
  InitRollbackWithdrawal = "InitRollbackWithdrawal",
  InitWithdrawal = "InitWithdrawal",
  JackpotWin = "JackpotWin",
  NegativeBet = "NegativeBet",
  RollbackCasinoBet = "RollbackCasinoBet",
  RollbackCasinoWin = "RollbackCasinoWin",
  RollbackDeposit = "RollbackDeposit",
  RollbackJackpotWin = "RollbackJackpotWin",
  RollbackWithdrawal = "RollbackWithdrawal",
  Withdrawal = "Withdrawal",
}

/**
 * Verification decision for Source of Wealth
 */
export enum VerificationDecision {
  Approved = "Approved",
  Rejected = "Rejected",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
