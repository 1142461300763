import React, { useMemo } from 'react';
import warning from 'warning';
import { Calendar } from '@material-ui/pickers';
import { FieldRenderProps } from 'react-final-form';

interface PropsType {
  pickerFormat: string;
}

const CalendarFormField = (props: PropsType & FieldRenderProps<Date, HTMLElement>) => {
  const { meta, input, pickerFormat, ...other } = props;

  const now = useMemo(() => new Date(), []);
  const { onChange, value } = input;

  warning(value, 'Value of CalendarFormField is not a date');

  return <Calendar {...other} date={value ? new Date(value) : now} onChange={onChange} />;
};

export default CalendarFormField;
