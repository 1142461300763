import React, { useCallback } from 'react';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/react-hooks';

import Button from '@material-ui/core/Button';
import { ClosureStatus } from '@/types/generated/globalTypes';
import { CancelTimeout } from '@/types/generated/CancelTimeout';
import PlayerClosureHistoryTable from './PlayerClosureHistoryTable';
import { PlayerClosureHistoryTableRow } from '@/types/generated/PlayerClosureHistoryTableRow';

interface PropsType {
  playerId: string
  timeoutId: string
  status: ClosureStatus
}

const CANCEL_TIMEOUT = gql`
  mutation CancelTimeout($playerId: ID!, $timeoutId: ID!) {
    cancelTimeout(playerId: $playerId, timeoutId: $timeoutId) {
      id
    }
  }
`;

const CancelTimeoutButton = (props: PropsType) => {
  const { playerId, timeoutId, status } = props;

  const [cancelTimeout] = useMutation<CancelTimeout>(CANCEL_TIMEOUT);
  const { enqueueSnackbar } = useSnackbar();

  const onClick = useCallback(
    async _values => {
      try {
        await cancelTimeout({
          variables: {
            playerId,
            timeoutId,
          },
          update(cache) {
            const row = cache.readFragment<PlayerClosureHistoryTableRow>({
              id: timeoutId,
              fragment: PlayerClosureHistoryTable.fragments.row,
            });
            if (row) {
              cache.writeFragment({
                id: timeoutId,
                fragment: PlayerClosureHistoryTable.fragments.row,
                data: {
                  ...row,
                  status: 'Cancelled',
                  __typename: 'ClosureSearchItem',
                },
              });
            }
          }
        });
        enqueueSnackbar('Timeout has been cancelled', { variant: 'success' });
      } catch (e) {
        enqueueSnackbar(`Failed to cancel timeout: ${e}`, { variant: 'error' });
      }
    },
    [playerId, enqueueSnackbar, cancelTimeout]
  );

  return (
    <Button
      type="button"
      color="primary"
      variant="contained"
      disabled={status !== "Active"}
      onClick={onClick}
    >
      CANCEL
    </Button>
  )
}

export default CancelTimeoutButton;
