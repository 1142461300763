import React from 'react';
import gql from 'graphql-tag';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';

import TableRow from '@/components/TableRow';
import formatDate from '@/helpers/formatDate';
import Table, { PropsType as TablePropsType } from '@/components/Table';
import { PlayerClosureHistoryTableRow } from '@/types/generated/PlayerClosureHistoryTableRow';
import CancelTimeoutButton from './CancelTimeoutButton';
import CancelSelfExclusionButton from './CancelSelfExclusionButton';
import { ClosureType } from '@/constants';

interface PropsType extends TablePropsType {
  closureType: ClosureType;
  rows?: PlayerClosureHistoryTableRow[];
}

const PlayerClosureHistoryTable = (props: PropsType) => {
  const { rows = [], closureType, ...other } = props;

  return (
    <Table {...other}>
      <TableHead>
        <TableRow>
          <TableCell>Start Date</TableCell>
          <TableCell>End Date</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Comment</TableCell>
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map(row => (
          <TableRow cursor="pointer" key={row.startDate}>
            <TableCell>{formatDate(row.startDate)}</TableCell>
            <TableCell>{formatDate(row.endDate)}</TableCell>
            <TableCell>{row.status}</TableCell>
            <TableCell>{row.comment}</TableCell>
            <TableCell>
              {closureType === ClosureType.Timeout ?
                <CancelTimeoutButton playerId={row.playerId} timeoutId={row.id} status={row.status} /> :
                <CancelSelfExclusionButton playerId={row.playerId} exclusionId={row.id} status={row.status} />
              }
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

PlayerClosureHistoryTable.fragments = {
  row: gql`
    fragment PlayerClosureHistoryTableRow on ClosureSearchItem {
      __typename
      id
      status
      endDate
      comment
      playerId
      startDate
    }
  `,
};

export default PlayerClosureHistoryTable;
