import React, { FC, CSSProperties } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { Props as SelectProps } from 'react-select/lib/Select';
import { makeStyles, createStyles, useTheme } from '@material-ui/styles';

import { Theme } from '@material-ui/core';
import { placeholder } from '@/theme/settings';

import * as components from './components';

interface PropsType {
  async?: boolean;
  creatable?: boolean;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        height: 250,
      },
      input: {
        display: 'flex',
        height: 'auto',
        padding: '2px 0 1px',
      },
      valueContainer: {
        flex: 1,
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'hidden',
        boxSizing: 'border-box',
        alignItems: 'center',
        paddingLeft: 12,
      },
      chip: {
        margin: theme.spacing(0.5, 0.25),
      },
      chipFocused: {
        backgroundColor: emphasize(
          theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
          0.08
        ),
      },
      noOptionsMessage: {
        padding: theme.spacing(1, 2),
      },
      singleValue: {
        fontSize: 16,
      },
      placeholder: {
        ...placeholder,
        left: 13,
        bottom: 7,
        opacity: 0.42,
        position: 'absolute',
        fontSize: '0.75em',
        transition: 'opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      },
      paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
      },
      divider: {
        height: theme.spacing(2),
      },
    }),
  { name: 'ReactSelectMUI' }
);

const ReactSelectMUI: FC<SelectProps & PropsType> = props => {
  const { async, creatable } = props;
  const classes = useStyles(props);
  const theme: Theme = useTheme();

  const Component = async && creatable ? AsyncCreatableSelect : async ? AsyncSelect : Select;

  const selectStyles = {
    input: (base: CSSProperties) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };

  return <Component {...props} styles={selectStyles} classes={classes} components={components} />;
};

export default ReactSelectMUI;
