import React from 'react';
import Button from '@material-ui/core/Button';
import { Theme } from '@material-ui/core';
import { Link, LinkGetProps } from '@reach/router';
import { makeStyles, createStyles } from '@material-ui/styles';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      content: {
        width: 600,
      },
      sectionButton: {
        color: '#fff',
        border: '1px solid',
        fontSize: 12,
        boxShadow: 'none',
        lineHeight: 1.5,
        borderColor: '#5f3461',
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        backgroundColor: '#5f3461',
      },
    }),
  { name: 'SectionSwitcher' }
);

const getProps = (opts: LinkGetProps) => {
  const { isCurrent } = opts;

  const style = isCurrent
    ? {
        backgroundColor: '#f77874',
        borderColor: '#f77874',
      }
    : {};

  return { style };
};

interface MenuItem {
  to: string;
  label: string;
  disabled?: boolean;
}

interface PropsType {
  menu: MenuItem[];
}

const SectionSwitcher = (props: PropsType) => {
  const { menu = [] } = props;
  const classes = useStyles(props);

  return (
    <div>
      {menu.map((item, index) => (
        <Button
          to={item.to}
          key={item.to + index}
          size="small"
          disabled={item.disabled}
          getProps={getProps}
          component={Link}
          className={classes.sectionButton}
        >
          {item.label}
        </Button>
      ))}
    </div>
  );
};

export default SectionSwitcher;
