import React from 'react';
import idx from 'idx';
import gql from 'graphql-tag';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import formatMoney from '@/helpers/formatMoney';
import formatValue from '@/helpers/formatValue';
import { WalletDetailsFragment_player as Player } from '@/types/generated/WalletDetailsFragment_player';
import formatDate from '@/helpers/formatDate';

interface PropsType {
  player?: Player;
}

const WalletDetails = (props: PropsType) => {
  const { player } = props;

  const wallet = idx(player, (_) => _.wallet);
  const currency = idx(wallet, (_) => _.currency);
  const cashBalance = idx(wallet, (_) => _.balance);
  const reservedBalance = idx(wallet, (_) => _.reservedBalance);
  const pendingWithdrawalLimitAmount = wallet?.withdrawalLimit?.dayLimit?.available;
  const maxWithdrawalLimitAmount = wallet?.withdrawalLimit?.dayLimit?.value;
  const utcDate = new Date(wallet?.withdrawalLimit?.dayLimit?.currentPeriodEndTime).toUTCString();

  return (
    <div>
      <Typography paragraph variant="h4">
        WALLET ({formatValue(currency)})
      </Typography>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h5">CASH BALANCE</Typography>
          <Typography>{formatMoney(cashBalance, currency)}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5">RESERVED BALANCE</Typography>
          <Typography>{formatMoney(reservedBalance, currency)}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5">WITHDRAWAL LIMIT PENDING AMOUNT</Typography>
          <Typography>
            {formatMoney(pendingWithdrawalLimitAmount, currency)}
            {' of '}
            {formatMoney(maxWithdrawalLimitAmount, currency)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5">WITHDRAWAL LIMIT RESET TIME</Typography>
          <Typography>{utcDate}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

WalletDetails.fragments = {
  player: gql`
    fragment WalletDetailsFragment_player on Player {
      wallet {
        balance
        currency
        reservedBalance
        withdrawalLimit {
          dayLimit {
            available
            value
            currentPeriodEndTime
          }
        }
      }
    }
  `,
};

export default WalletDetails;
