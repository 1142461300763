import React, { useEffect, useContext } from 'react';
import idx from 'idx';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import AppContext from '@/contexts/AppContext';
import CurrentSelfExclusionValues from './CurrentSelfExclusionValues';
import PendingSelfExclusionValues from './PendingSelfExclusionValues';

interface PropsType {
  title: string;
  loading: boolean;
  selfExclusions: any;
  playerId: any;
}

const SelfExclusion = (props: PropsType) => {
  const { title, loading, selfExclusions, playerId } = props;

  const { setLoading } = useContext(AppContext);

  useEffect(() => {
    setLoading(!!loading);
  }, [loading, setLoading]);

  return (
    <div>
      <Typography paragraph variant="h4">
        {title}
      </Typography>
      <Box mt={4} />
      <CurrentSelfExclusionValues selfExclusions={selfExclusions} playerId={playerId} />
      <Box mt={4} />
      <PendingSelfExclusionValues selfExclusions={selfExclusions} playerId={playerId} />
    </div>
  );
};

export default SelfExclusion;
