import React from 'react';
import find from 'lodash-es/find';
import { TextFieldProps as TextFieldPropsType } from '@material-ui/core/TextField';

import ReactSelectMUI from '@/modules/react-select-mui';

import I18NISOCountries from '@/helpers/I18NISOCountries';

const COUNTRIES = I18NISOCountries.getNames('en');

interface OptionType {
  label: string;
  value: string;
}

interface PropsType {
  value: string;
  onChange: (value: string) => void;
  TextFieldProps?: TextFieldPropsType;
}

const CountrySelect = (props: PropsType) => {
  const { value: valueProp, onChange: onChangeProp, TextFieldProps = {}, ...other } = props;

  const options = Object.keys(COUNTRIES).map(countryCode => ({
    value: countryCode,
    label: COUNTRIES[countryCode],
  }));

  const onChange = (option: OptionType) => {
    onChangeProp(option === null ? option : option.value); // null if pressing "x" for "clear"
  };

  const value = find(options, { value: valueProp });

  return (
    <ReactSelectMUI
      value={value}
      options={options}
      onChange={onChange}
      TextFieldProps={TextFieldProps}
      {...other}
    />
  );
};

export default CountrySelect;
