import React, { FC, useCallback } from 'react';
import { FieldRenderProps } from 'react-final-form';
import BrandSelect from '@/containers/BrandSelect';

const BrandSelectFormField: FC<FieldRenderProps<string, HTMLElement>> = props => {
  const { input, meta, ...other } = props;
  const { value } = input;

  const onChange = useCallback(
    (_, value) => {
      input.onChange(value);
    },
    [input]
  );

  return <BrandSelect {...other} value={value} onChange={onChange} />;
};

export default BrandSelectFormField;
