import React, { useState, useCallback } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Theme } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { makeStyles, createStyles } from '@material-ui/styles';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      paper: {
        padding: theme.spacing(3, 5),
      },
    }),
  { name: 'PendingLimits' }
);

type Limit = string | number;

interface PropsType {
  day?: Limit | null;
  week?: Limit | null;
  month?: Limit | null;
  onCancelPendingRequests: Function;
}

function formatLimit(limit?: Limit) {
  if (!limit && limit !== 0) {
    return '-';
  }

  return String(limit);
}

const PendingLimits = (props: PropsType) => {
  const { day, week, month, onCancelPendingRequests: onCancelPendingRequestsProp } = props;
  const classes = useStyles(props);

  const { enqueueSnackbar } = useSnackbar();
  const [canceling, setCanceling] = useState(false);

  const onCancelPendingRequests = useCallback(async () => {
    try {
      setCanceling(true);
      await onCancelPendingRequestsProp();
      enqueueSnackbar('Limits has been canceled', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(`Failed to cancel limits: ${e}`, { variant: 'error' });
    } finally {
      setCanceling(false);
    }
  }, [onCancelPendingRequestsProp, enqueueSnackbar, setCanceling]);

  return (
    <Paper className={classes.paper}>
      <Typography paragraph variant="h4">
        PENDING LIMITS
      </Typography>
      <Box mt={3} />
      <Typography paragraph>
        The customer has requested limits on their account. These requests can be cancelled within
        24 hours by an agent, otherwise the Iimits will be set in place as requested
      </Typography>
      <Box mt={3} />
      <Grid container spacing={8}>
        <Grid item>
          <Typography gutterBottom variant="h5">
            DAILY
          </Typography>
          <Typography>{formatLimit(day)}</Typography>
        </Grid>
        <Grid item>
          <Typography gutterBottom variant="h5">
            WEEKLY
          </Typography>
          <Typography>{formatLimit(week)}</Typography>
        </Grid>
        <Grid item>
          <Typography gutterBottom variant="h5">
            MONTHLY
          </Typography>
          <Typography>{formatLimit(month)}</Typography>
        </Grid>
      </Grid>
      <Box mt={3} />
      <Button
        fullWidth
        color="primary"
        variant="contained"
        onClick={onCancelPendingRequests}
        disabled={canceling || !(day || week || month)}
      >
        CANCEL PENDING REQUESTS
      </Button>
    </Paper>
  );
};

export default PendingLimits;
