import React, { memo } from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';
import findIndex from 'lodash-es/findIndex';
import { makeStyles } from '@material-ui/styles';
import { Location, Link } from '@reach/router';

import { Theme } from '@material-ui/core';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    appBar: {
      color: '#fff',
      backgroundColor: '#5f3461',
    },
    tab: {
      minWidth: 'auto',
      minHeight: 64,
      color: '#fff',
    },
  }),
  { name: 'AppNavMenu' }
);

export interface AppNavMenuItemType {
  route: string;
  label: string;
  disabled?: boolean;
  isActive?: (pathname: string) => boolean;
}

export interface AppNavMenuPropsType {
  menu: AppNavMenuItemType[];
}

const AppNavMenu = (props: AppNavMenuPropsType) => {
  const { menu } = props;

  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Location>
          {({ location }) => {
            const { pathname } = location;

            const value = findIndex(menu, (item: AppNavMenuItemType) => {
              const isExact = item.route === pathname.substr(0, item.route.length);
              const isActive = !!item.isActive && item.isActive(pathname);
              return isExact || isActive;
            });

            return (
              <Tabs value={value} variant="scrollable" scrollButtons="auto">
                {menu.map((item: AppNavMenuItemType) => (
                  <Tab
                    to={item.route}
                    key={item.label}
                    label={item.label}
                    disabled={item.disabled}
                    component={Link}
                    className={classes.tab}
                  />
                ))}
              </Tabs>
            );
          }}
        </Location>
      </AppBar>
    </div>
  );
};

export default memo(AppNavMenu);
