import React, { FC } from 'react';
import CountrySelect from '@/containers/CountrySelect';
import { FieldRenderProps } from 'react-final-form';

const CountrySelectFormField: FC<FieldRenderProps<string, HTMLElement>> = props => {
  const { input, meta, ...other } = props;
  const { value, onChange } = input;

  return <CountrySelect {...other} value={value} onChange={onChange} />;
};

export default CountrySelectFormField;
