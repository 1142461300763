import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { StaticQuery, graphql } from 'gatsby';

import Okta from './Okta';

const Login = (props: RouteComponentProps) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          logo: file(relativePath: { eq: "logo.png" }) {
            childImageSharp {
              fluid(maxHeight: 80) {
                src
              }
            }
          }
        }
      `}
    >
      {data => <Okta logo={data.logo.childImageSharp.fluid.src} {...props} />}
    </StaticQuery>
  );
};

export default Login;
