import React from 'react';
import Typography from '@material-ui/core/Typography';
import MUITable, { TableProps } from '@material-ui/core/Table';
import { makeStyles, createStyles } from '@material-ui/styles';

import TablePagination, { TablePaginationPropsType } from '@/components/TablePagination';

export interface PropsType extends TableProps {
  error?: Error;
  loading?: boolean;
  rowsCount?: number;
  TablePaginationProps?: TablePaginationPropsType;
}

const useStyles = makeStyles(
  createStyles({
    tableWrapper: {
      overflow: 'auto',
    },
  }),
  { name: 'Table' }
);

const Table = (props: PropsType) => {
  const { error, loading, rowsCount, TablePaginationProps, ...other } = props;

  const classes = useStyles(props);

  return (
    <div>
      {loading === false && TablePaginationProps && (
        <TablePagination
          component="div"
          nextIconButtonProps={{ 'aria-label': 'Next page' }}
          backIconButtonProps={{ 'aria-label': 'Previous page' }}
          {...TablePaginationProps}
        />
      )}
      <div className={classes.tableWrapper}>
        <MUITable {...other} />
      </div>
      {loading === false && !rowsCount && !error && (
        <Typography variant="h4" align="center">
          No results have been found
        </Typography>
      )}
      {!!error && (
        <Typography variant="h4" align="center">
          {String(error)}
        </Typography>
      )}
      {loading === false && TablePaginationProps && (
        <TablePagination
          component="div"
          nextIconButtonProps={{ 'aria-label': 'Next page' }}
          backIconButtonProps={{ 'aria-label': 'Previous page' }}
          {...TablePaginationProps}
        />
      )}
    </div>
  );
};

export default Table;
