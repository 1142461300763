import React, { useState, useCallback, useEffect } from 'react';
import idx from 'idx';
import gql from 'graphql-tag';
import get from 'lodash-es/get';
import Select, { SelectProps } from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FilledInput from '@material-ui/core/FilledInput';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Omit } from 'utility-types';

import { Theme } from '@material-ui/core';

import {
  BrandSelect as BrandSelectType,
  BrandSelect_viewer_brands,
} from '@/types/generated/BrandSelect';
import { useIdentity } from './useIdentity';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      select: {
        minWidth: 100,
      },
      filledInput__input: {
        paddingTop: 10,
        paddingRight: theme.spacing(3),
      },
    }),
  { name: 'BrandSelect' }
);

interface BrandType {
  id: string;
  name: string;
}

interface PropsType extends Omit<SelectProps, 'onChange'> {
  onChange?: (event: React.MouseEvent<HTMLElement> | null, value: unknown) => void;
  allowAll?: boolean;
}

const BrandSelect: React.FC<PropsType> = (props) => {
  const { value, allowAll, onChange: onChangeProp = () => {}, ...other } = props;

  const classes = useStyles(props);

  const [items, setItems] = useState<BrandSelect_viewer_brands[]>([]);

  const onChange = useCallback(
    (event) => {
      const value = event.target.value === '_all' ? '' : event.target.value;
      onChangeProp(event, value);
    },
    [onChangeProp]
  );

  const { data, loading } = useQuery<BrandSelectType>(gql`
    query BrandSelect {
      viewer {
        brands {
          code
          name
        }
      }
    }
  `);

  const input = <FilledInput classes={{ input: classes.filledInput__input }} />;
  const { brands: identityBrands, loading: loadingBrands } = useIdentity();

  useEffect(() => {
    if (loading || loadingBrands) {
      return;
    }

    const brands = idx(data, (_) => _.viewer.brands) || [];
    const filteredBrands = brands.filter((elem) => {
      return identityBrands.includes(elem.code);
    });

    if (!value) {
      const value = allowAll ? '' : get(brands, '0.code');
      onChangeProp(null, value);
    }

    setItems(filteredBrands);
  }, [allowAll, data, loading, onChangeProp, value]);

  if (loading && !value && value !== '') {
    return (
      <Select
        {...other}
        displayEmpty
        disableUnderline
        value="loading"
        input={input}
        className={classes.select}
      >
        <MenuItem value="loading" disabled>
          Loading...
        </MenuItem>
      </Select>
    );
  }

  return (
    <Select
      {...other}
      disableUnderline
      input={input}
      value={value === '' ? '_all' : value}
      onChange={onChange}
      className={classes.select}
    >
      {allowAll && <MenuItem value="_all">All</MenuItem>}
      {items.map((item) => (
        <MenuItem key={item.code} value={item.code}>
          {item.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default BrandSelect;
