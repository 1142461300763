import React, { FC, useEffect, useCallback, useState, useContext } from 'react';
import get from 'lodash-es/get'
import clsx from 'clsx';
import Helmet from 'react-helmet';
import { RouteComponentProps } from '@reach/router';
import { makeStyles, createStyles } from '@material-ui/styles';

import Spinner from '@/components/Spinner';
import { getOkta } from '@/helpers/okta'
import { navigateToRedirect } from '@/helpers/redirect'
import SessionContext, { SessionStatus } from '@/contexts/SessionContext';

const useStyles = makeStyles(
  createStyles({
    '@global #okta-sign-in': {
      margin: '0 auto !important',
    },
    root: {
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    okta_loading: {
      display: 'none'
    }
  }),
  { name: 'Okta' }
);

const Okta: FC<RouteComponentProps & { logo: string }> = props => {
  const { logo } = props;

  const okta = getOkta()
  const classes = useStyles(props);
  const session = useContext(SessionContext);
  const isSessionLoading = session.status === SessionStatus.LOADING
  const [isStylesLoading, setIsStylesLoading] = useState(true);
  const [isOktaInitialized, setIsOktaInitialized] = useState(false);

  const onSuccess = useCallback((res) => {
    session.setOktaTokens(res);
    navigateToRedirect('/');
  }, [session])

  const onFailure = useCallback((err) => {
    console.error('Error signing in:', err);
  }, [])

  useEffect(() => {
    if (isOktaInitialized) {
      return
    }

    okta.renderEl({ el: '#okta-login', logo }, onSuccess, onFailure);
    setIsOktaInitialized(true)
  }, [logo, okta, isOktaInitialized, onSuccess, onFailure]);

  useEffect(() => {
    if (session.status === SessionStatus.LOGGED_IN) {
      navigateToRedirect('/')
    }
  }, [session, session.status]);

  useEffect(() => {
    const tid = setTimeout(() => setIsStylesLoading(false), 5000);
    return () => clearTimeout(tid);
  }, [setIsStylesLoading]);

  const onChangeClientState = useCallback((_, addedTags) => {
    const link = get(addedTags, 'linkTags[0]');
    const load = () => setIsStylesLoading(false);

    if (link) {
      link.addEventListener('load', load);
    }

    return () => {
      if (link) {
        link.removeEventListener('load', load);
      }
    }
  }, [setIsStylesLoading])

  const loading = isSessionLoading || isStylesLoading

  return (
    <div className={classes.root}>
      <Helmet onChangeClientState={onChangeClientState}>
        <link
          rel="stylesheet"
          href="https://global.oktacdn.com/okta-signin-widget/3.4.0/css/okta-sign-in.min.css"
          type="text/css"
        />
      </Helmet>
      {loading && <Spinner />}
      <div id="okta-login" className={clsx(loading && classes.okta_loading)} />
    </div>
  );
};

export default Okta;
