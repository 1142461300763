import React, { memo, useState } from 'react';
import idx from 'idx';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import formatDate from '@/helpers/formatDate';

import CancelSelfExclusionButton from './CancelSelfExclusionButton';

interface PropsType {
  selfExclusions: any;
  playerId: any;
}

const CurrentSelfExclusionValues = (props: PropsType) => {
  const { playerId, selfExclusions } = props;

  if (!selfExclusions) {
    return null;
  }

  return (
    <Paper>
      <Box pt={3} pr={5} pb={3} pl={5}>
        <Typography paragraph variant="h4">
          CURRENT SELF EXCLUSION
        </Typography>
        <Grid container spacing={8}>
          <Grid item>
            <Typography variant="h5">Configured At</Typography>
            <Typography>{formatDate(idx(selfExclusions, (_) => _.configuredAt))}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5">Ends at</Typography>
            <Typography>{formatDate(idx(selfExclusions, (_) => _.exclusionEndsAt))}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5">Will be cancelled at</Typography>
            <Typography>{formatDate(idx(selfExclusions, (_) => _.willBeCancelledAt))}</Typography>
          </Grid>
          <Grid item sm={12}>
            <Typography variant="h5">Revoke Comment</Typography>
            <CancelSelfExclusionButton
              disabled={selfExclusions.willBeCancelledAt != null}
              playerId={playerId}
              exclusionId={selfExclusions.id}
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default memo(CurrentSelfExclusionValues);
