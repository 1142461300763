import React from 'react';
import gql from 'graphql-tag';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';

import formatDate from '@/helpers/formatDate';
import renderInitiator from '@/helpers/renderInitiator';
import Table, { PropsType as TablePropsType } from '@/components/Table';
import { PlayerLimitsHistoryTableRow } from '@/types/generated/PlayerLimitsHistoryTableRow';

interface PropsType extends TablePropsType {
  rows?: PlayerLimitsHistoryTableRow[];
}

const PlayerLimitsHistory = (props: PropsType) => {
  const { rows = [], ...other } = props;

  return (
    <Table {...other}>
      <TableHead>
        <TableRow>
          <TableCell>Requested At</TableCell>
          <TableCell>Confirmed At</TableCell>
          <TableCell>Available At</TableCell>
          <TableCell>Period</TableCell>
          <TableCell>Previous Value</TableCell>
          <TableCell>New Value</TableCell>
          <TableCell>Requested By</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map(row => (
          <TableRow key={row.requestedAt}>
            <TableCell>{formatDate(row.requestedAt)}</TableCell>
            <TableCell>{formatDate(row.confirmedAt)}</TableCell>
            <TableCell>{formatDate(row.availableAt)}</TableCell>
            <TableCell>{row.period}</TableCell>
            <TableCell>{row.prevValue}</TableCell>
            <TableCell>{row.newValue}</TableCell>
            <TableCell>{renderInitiator(row.requestedBy)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

PlayerLimitsHistory.fragments = {
  row: gql`
    fragment PlayerLimitsHistoryTableRow on LimitSearchItem {
      period
      newValue
      prevValue
      confirmedAt
      requestedAt
      availableAt
      requestedBy {
        ... on AgentInitiator {
          agent {
            email
          }
        }
        ... on PlayerInitiator {
          player {
            email
          }
        }
      }
    }
  `,
};

export default PlayerLimitsHistory;
