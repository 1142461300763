import React, { FC } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { KeyboardDatePicker } from '@material-ui/pickers';

interface PropsType {
  pickerFormat?: string;
}

const KeyboardDatePickerFormField: FC<PropsType & FieldRenderProps<Date, HTMLElement>> = props => {
  const { meta, input, pickerFormat, ...rest } = props;
  const { name, onChange, value } = input;

  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

  return (
    <KeyboardDatePicker
      {...rest}
      name={name}
      error={showError}
      value={value || null}
      format={pickerFormat}
      onChange={onChange}
      helperText={showError ? meta.error || meta.submitError : undefined}
      KeyboardButtonProps={{ style: { color: '#fff', paddingRight: 0 } }}
    />
  );
};

export default KeyboardDatePickerFormField;
