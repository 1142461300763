import React from 'react';
import Typography from '@material-ui/core/Typography';

import SelfExclusionForm from './SelfExclusionForm';

import ShowSelfExclusion from './ShowSelfExclusion';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import idx from 'idx';

interface PropsType {
  playerId: string;
}

const GetSelfExclusions = gql`
  query GetSelfExclusions($playerId: ID!) {
    player(playerId: $playerId) {
      ... on Player {
        selfExclusionDetails {
          id
          configuredAt
          exclusionEndsAt
          willBeCancelledAt
          pending {
            activeFrom
            configuredAt
            exclusionEndsAt
          }
        }
      }
    }
  }
`;

const PlayerClosureSelfExclusion = (props: PropsType) => {
  const { playerId } = props;

  const { data, loading, refetch } = useQuery<any>(GetSelfExclusions, { variables: { playerId } });

  const selfExclusions = idx(data, (_) => _.player.selfExclusionDetails);

  return (
    <>
      <div>
        <Typography paragraph variant="h4">
          SELF EXCLUSION
        </Typography>
        <SelfExclusionForm refetch={refetch} data={selfExclusions} playerId={playerId} />
        <ShowSelfExclusion
          loading={loading}
          selfExclusions={selfExclusions}
          playerId={playerId}
        ></ShowSelfExclusion>
      </div>
    </>
  );
};

PlayerClosureSelfExclusion.fragments = {
  player: gql`
    fragment PlayerClosureSelfExclusion_player on Player {
      selfExclusionDetails {
        id
        configuredAt
        exclusionEndsAt
        willBeCancelledAt
        pending {
          activeFrom
          configuredAt
          exclusionEndsAt
        }
      }
    }
  `,
};

export default PlayerClosureSelfExclusion;
