import React, { useCallback } from 'react';
import idx from 'idx';
import gql from 'graphql-tag';
import merge from 'lodash-es/merge';
import { useQuery, useMutation } from '@apollo/react-hooks';

import SelfExclusion from '@/containers/SelfExclusion';
interface PropsType {
  playerId: string;
  exclusionId?: string;
  selfExclusions: any;
  loading: boolean;
}

const ShowSelfExclusion = (props: PropsType) => {
  const { playerId, selfExclusions, loading } = props;

  return (
    <>
      <SelfExclusion
        title="SELF EXCLUSION UPDATE"
        loading={loading}
        selfExclusions={selfExclusions}
        playerId={playerId}
      />
    </>
  );
};

export default ShowSelfExclusion;
