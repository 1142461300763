import React, { FC } from "react";
import { TableCell, TableCellProps } from "@material-ui/core";
import { Link } from "@reach/router";

type Props = {
  to: string;
}

const NavigatableTableCell: FC<Props & TableCellProps> = ({ children, to, ...props }) =>
  <TableCell {...props} style={{ padding: 0 }}>
    <Link to={to} style={{ width: '100%', display: 'inline-flex', padding: '24px 12px', }}>
      {children}
    </Link>
  </TableCell>

export default NavigatableTableCell;