import React, { useEffect, useContext, useCallback } from 'react';
import gql from 'graphql-tag';
import idx from 'idx';
import map from 'lodash-es/map';
import Typography from '@material-ui/core/Typography';
import { useQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import AppContext from '@/contexts/AppContext';
import PageLayout from '@/components/PageLayout';
import { LimitType } from '@/constants';

import PlayerLimitsHistoryTable from './PlayerLimitsHistoryTable';

export interface PropsType {
  title: string;
  playerId: string;
  limitType: LimitType;
  after: string;
  before: string;
}

const GetPlayerLimits = gql`
  query GetPlayerLimits($playerId: ID!, $limitType: LimitType, $after: String, $before: String) {
    player(playerId: $playerId) {
      id
      limits(limitType: $limitType, after: $after, before: $before) {
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
        edges {
          node {
            ...PlayerLimitsHistoryTableRow
          }
        }
      }
    }
  }
  ${PlayerLimitsHistoryTable.fragments.row}
`;

const PlayerLimitsHistory = (props: PropsType) => {
  const { title, playerId, limitType } = props;

  const { setLoading } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();

  const { data, error, loading, fetchMore } = useQuery(GetPlayerLimits, {
    variables: {
      playerId,
      limitType,
    },
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  const rows = map(
    idx(data, (_) => _.player.limits.edges),
    (item) => {
      return idx(item, (_) => _.node);
    }
  );

  const onPaginate = useCallback(
    async ({ forward }) => {
      try {
        const pageInfo = idx(data, (_) => _.player.limits.pageInfo);
        const endCursor = idx(pageInfo, (_) => _.endCursor);
        const startCursor = idx(pageInfo, (_) => _.startCursor);

        setLoading(true);

        await fetchMore({
          query: GetPlayerLimits,
          variables: forward ? { after: endCursor, playerId } : { before: startCursor, playerId },
          updateQuery: (prev, curr) => curr.fetchMoreResult || prev,
        });
      } catch (e) {
        enqueueSnackbar(`Failed to load items: ${e}`, { variant: 'error' });
      } finally {
        setLoading(false);
      }
    },
    [data, setLoading, enqueueSnackbar, fetchMore]
  );

  return (
    <PageLayout>
      <Typography paragraph variant="h4">
        {title}
      </Typography>
      <PlayerLimitsHistoryTable
        rows={rows}
        error={error}
        loading={loading}
        rowsCount={rows && rows.length}
        TablePaginationProps={{
          onPaginate,
          hasNextPage: idx(data, (_) => _.player.limits.pageInfo.hasNextPage),
          hasPrevPage: idx(data, (_) => _.player.limits.pageInfo.hasPreviousPage),
        }}
      />
    </PageLayout>
  );
};

export default PlayerLimitsHistory;
