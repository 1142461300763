import React, { useCallback, ReactNode } from 'react';
import noop from 'lodash-es/noop';
import MUITablePagination, { TablePaginationProps } from '@material-ui/core/TablePagination';

type PartialTablePaginationPropsType = Omit<
  TablePaginationProps,
  'page' | 'count' | 'rowsPerPage' | 'rowsPerPageOptions' | 'onChangePage'
>;

export interface TablePaginationPropsType extends PartialTablePaginationPropsType {
  component?: ReactNode;
  onPaginate?: Function;
  hasNextPage?: boolean | null;
  hasPrevPage?: boolean | null;
}

const TablePagination = (props: TablePaginationPropsType) => {
  const { hasPrevPage, hasNextPage, onPaginate = noop, ...other } = props;

  let fakeProps = {};
  if (hasPrevPage && hasNextPage) {
    fakeProps = { page: 1 };
  } else if (hasPrevPage) {
    fakeProps = { page: 2 };
  } else if (hasNextPage) {
    fakeProps = { page: 0 };
  } else {
    fakeProps = { page: 0, count: 0 };
  }

  const onChangePage = useCallback(
    (_, pageNumber: number) => {
      onPaginate({ forward: hasNextPage && pageNumber > 0 });
    },
    [onPaginate, hasNextPage]
  );

  return (
    <MUITablePagination
      page={1}
      count={3}
      rowsPerPage={1}
      onChangePage={onChangePage}
      labelDisplayedRows={() => ''}
      rowsPerPageOptions={[1]}
      {...fakeProps}
      {...other}
    />
  );
};

export default TablePagination;
