import format from 'date-fns/format';
import { PLACEHOLDER } from '@/constants';

export default function formatDate(date?: Date | string | null, hideHour?: boolean) {
  if (!date) {
    return PLACEHOLDER;
  }

  return format(new Date(date), hideHour ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm');
}
