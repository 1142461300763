import React, { useCallback } from 'react';
import gql from 'graphql-tag';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/react-hooks';

import Button from '@material-ui/core/Button';
import { CancelSelfExclusion } from '@/types/generated/CancelSelfExclusion';
import { PlayerClosureHistoryTableRow } from '@/types/generated/PlayerClosureHistoryTableRow';
import PlayerClosureHistoryTable from '../PlayerClosureHistory/PlayerClosureHistoryTable';
import SelfExclusion from '.';
import { GET_PLAYER_CLOSURES } from '../PlayerClosureHistory';
import { ClosureType } from '@/constants';
import { PlayerClosures, PlayerClosuresVariables } from '@/types/generated/PlayerClosures';
import idx from 'idx';
import { GetSelfExclusions, GetSelfExclusionsVariables } from '@/types/generated/GetSelfExclusions';
import { Form, Field } from 'react-final-form';
import { FormControl } from '@material-ui/core';
import TextFieldFormField from '@/form-fields/TextField';

interface PropsType {
  playerId: string;
  exclusionId: string;
  disabled: boolean;
}

const CANCEL_SELF_EXCLUSION = gql`
  mutation CancelSelfExclusionV3($playerId: ID!, $comment: String!) {
    cancelSelfExclusionV3(playerId: $playerId, comment: $comment) {
      selfExclusionDetails {
        id
        configuredAt
        exclusionEndsAt
        willBeCancelledAt
        pending {
          activeFrom
          configuredAt
          exclusionEndsAt
        }
      }
    }
  }
`;

const CancelSelfExclusionButton = (props: PropsType) => {
  const { playerId, exclusionId, disabled } = props;

  const [cancelSelfExclusionV3] = useMutation<CancelSelfExclusion>(CANCEL_SELF_EXCLUSION);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (data: any) => {
    console.log(data);
    const comment = data?.comment;
    cancelSelfExclusionV3({
      variables: {
        playerId,
        comment,
      },
      update(cache, response) {
        const node = response.data.selfExclusionDetails;

        const data = cache.readQuery<GetSelfExclusions>({
          query: GET_PLAYER_CLOSURES,
          variables: {
            playerId,
          },
        });

        cache.writeQuery<GetSelfExclusions, GetSelfExclusionsVariables>({
          query: GET_PLAYER_CLOSURES,
          data: Object.assign({}, data, {
            player: {
              id: playerId,
              __typename: 'Player',
              selfExclusionDetails: {
                __typename: 'SelfExclusionData',
                ...(idx(data, (_) => _.player.selfExclusionDetails) || []),
              },
            },
          }),
          variables: {
            playerId,
          },
        });
      },
    });
  };

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth margin="normal">
            <Field
              multiline
              fullWidth
              name="comment"
              rows="4"
              variant="filled"
              required
              component={TextFieldFormField}
              placeholder="Write a comment"
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <Button type="submit" color="primary" disabled={disabled} variant="contained">
              REVOKE
            </Button>
          </FormControl>
        </form>
      )}
    </Form>
  );
};

export default CancelSelfExclusionButton;
