import { createElement } from 'react';
import MUITableRow, { TableRowProps } from '@material-ui/core/TableRow';

export interface PropsType extends TableRowProps {
  cursor?: 'pointer';
}

const TableRow = (props: PropsType) => {
  const { cursor, style: styleProp, ...other } = props;

  const style = {
    cursor,
    ...(styleProp || {}),
  };

  return createElement(MUITableRow, { style, ...other });
};

export default TableRow;
