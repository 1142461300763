import idx from 'idx';
import { PLACEHOLDER } from '@/constants';
import { AnyObject } from '@/types/helpers';

export default function renderInitiator(initiator?: AnyObject | null) {
  if (!initiator) {
    return PLACEHOLDER;
  }

  // const isAgent = idx(rb, _ => _.agent.email)
  // const id = idx(rb, _ => _.agent.id) || idx(rb, _ => _.player.id);
  const email = idx(initiator, _ => _.agent.email) || idx(initiator, _ => _.player.email);

  return email || PLACEHOLDER;
}
