import React, { useEffect, useContext, useCallback } from 'react';
import idx from 'idx';
import gql from 'graphql-tag';
import map from 'lodash-es/map';
import Typography from '@material-ui/core/Typography';
import { useQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import AppContext from '@/contexts/AppContext';
import PageLayout from '@/components/PageLayout';
import { ClosureType } from '@/constants';

import PlayerClosureHistoryTable from './PlayerClosureHistoryTable';

export interface PropsType {
  playerId: string;
  closureType: ClosureType;
}

export const GET_PLAYER_CLOSURES = gql`
  query PlayerClosures($playerId: ID!, $closureType: ClosureType) {
    player(playerId: $playerId) {
      id
      closures(closureType: $closureType, orderBy: startDate, desc: true) {
        edges {
          node {
            ...PlayerClosureHistoryTableRow
          }
        }
      }
    }
  }
  ${PlayerClosureHistoryTable.fragments.row}
`;

const PlayerClosureHistory = (props: PropsType) => {
  const { playerId, closureType } = props;

  const { setLoading } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();

  const { data, error, loading, fetchMore } = useQuery(GET_PLAYER_CLOSURES, {
    variables: {
      playerId,
      closureType,
    },
  });

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  const rows = map(idx(data, _ => _.player.closures.edges), item => {
    return idx(item, _ => _.node);
  });

  const title = closureType === ClosureType.Timeout ? 'TIME OUT HISTORY' : 'SELF EXCLUSION HISTORY';

  const onPaginate = useCallback(
    async ({ forward }) => {
      try {
        const pageInfo = idx(data, _ => _.player.closures.pageInfo);
        const endCursor = idx(pageInfo, _ => _.endCursor);
        const startCursor = idx(pageInfo, _ => _.startCursor);

        setLoading(true);

        const cursor = forward ? { after: endCursor } : { before: startCursor };
        const variables = {
          ...cursor,
          playerId,
          closureType,
        };

        await fetchMore({
          variables,
          query: GET_PLAYER_CLOSURES,
          updateQuery: (prev, curr) => curr.fetchMoreResult || prev,
        });
      } catch (e) {
        enqueueSnackbar(`Failed to load items: ${e}`, { variant: 'error' });
      } finally {
        setLoading(false);
      }
    },
    [data, setLoading, enqueueSnackbar, fetchMore, closureType, playerId]
  );

  return (
    <PageLayout>
      <Typography paragraph variant="h4">
        {title}
      </Typography>
      <PlayerClosureHistoryTable
        closureType={closureType}
        rows={rows}
        error={error}
        loading={loading}
        rowsCount={rows && rows.length}
        TablePaginationProps={{
          onPaginate,
          hasNextPage: idx(data, _ => _.player.closures.pageInfo.hasNextPage),
          hasPrevPage: idx(data, _ => _.player.closures.pageInfo.hasPreviousPage),
        }}
      />
    </PageLayout>
  );
};

export default PlayerClosureHistory;
